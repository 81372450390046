import React from 'react'
import { Container } from 'react-bootstrap'
import { useEffect } from 'react'
import AOS from 'aos';


function GaneshFest() {
    useEffect(() => {
        AOS.init({
          duration : 1500
        });
        AOS.refresh();
    
      }, []);

  return (
    <div className='overflow-hidden '>
        <Container className="px-5">
            <header className="sectionHeader halfBorder mb-5 headerLeftMargin">Ganesh Festival</header>

            <div className="gallery d-flex flex-wrap"> 
                <figure data-aos="fade-up-right">
                    <img fluid  src="/photo_gallery/ganesh_fest/ganesh1.jpeg" alt="ganesh1" />
                </figure>   
                <figure data-aos="fade-up-left">
                    <img fluid  src="/photo_gallery/ganesh_fest/ganesh2.jpg" alt="ganesh2" />
                </figure> 
                <figure data-aos="fade-up-right">
                    <img fluid  src="/photo_gallery/ganesh_fest/ganesh3.jpg" alt="ganesh3" />
                </figure>   
                <figure data-aos="fade-up-left">
                    <img fluid  src="/photo_gallery/ganesh_fest/ganesh4.jpeg" alt="ganesh4" />
                </figure>
                <figure data-aos="fade-up-left">
                    <img fluid  src="/photo_gallery/ganesh_fest/ganesh5.jpg" alt="ganesh5" />
                </figure>
                <figure data-aos="fade-up-left">
                    <img fluid  src="/photo_gallery/ganesh_fest/ganesh6.jpg" alt="ganesh6" />
                </figure>
                <figure data-aos="fade-up-left">
                    <img fluid  src="/photo_gallery/ganesh_fest/ganesh7.jpeg" alt="ganesh7" />
                </figure>
                 
               
            </div>


        </Container>
    </div>
  )
}

export default GaneshFest