import React from 'react'
import { Container } from 'react-bootstrap'
import { useEffect } from 'react'
import AOS from 'aos';


function GovardhanUtsav() {

    useEffect(() => {
        AOS.init({
          duration : 1500
        });
        AOS.refresh();
    
    }, []);
      
  return (
    <div className='overflow-hidden '>
        <Container className="px-5">
            <header className="sectionHeader halfBorder mb-5 headerLeftMargin">Govardhan Utsav</header>

            <div className="gallery d-flex flex-wrap"> 
                <figure data-aos="fade-up-right">
                    <img fluid  src="/photo_gallery/govardhan_utsav/DSC_0743.jpg" alt="" />
                </figure>   
                <figure data-aos="fade-up-left">
                    <img fluid  src="/photo_gallery/govardhan_utsav/DSC_0706.jpg" alt="" />
                </figure> 
                <figure data-aos="fade-up-right">
                    <img fluid  src="/photo_gallery/govardhan_utsav/DSC_0690.jpg" alt="" />
                </figure>   
                <figure data-aos="fade-up-left">
                    <img fluid  src="/photo_gallery/govardhan_utsav/DSC_0687.jpg" alt="" />
                </figure>
                <figure data-aos="fade-up-right">
                    <img fluid  src="/photo_gallery/govardhan_utsav/DSC_0278.jpeg" alt="" />
                </figure>   
                <figure data-aos="fade-up-left">
                    <img fluid  src="/photo_gallery/govardhan_utsav/DSC_0733.jpg" alt="" />
                </figure> 
                <figure data-aos="fade-up-right">
                    <img fluid  src="/photo_gallery/govardhan_utsav/DSC_0832.jpg" alt="" />
                </figure>   
                <figure data-aos="fade-up-left">
                    <img fluid  src="/photo_gallery/govardhan_utsav/DSC_0890.jpg" alt="" />
                </figure>
                <figure data-aos="fade-up-right">
                    <img fluid  src="/photo_gallery/govardhan_utsav/DSC_0935.jpg" alt="" />
                </figure>   
                <figure data-aos="fade-up-left">
                    <img fluid  src="/photo_gallery/govardhan_utsav/DSC_1034.jpg" alt="" />
                </figure> 
               
            </div>


        </Container>
    </div>
  )
}

export default GovardhanUtsav