import React from 'react'
import { Container } from 'react-bootstrap'
import { useEffect } from 'react'
import AOS from 'aos';


function SwamijiVisit() {
    useEffect(() => {
        AOS.init({
          duration : 1500
        });
        AOS.refresh();
    
      }, []);

  return (
    <div className='overflow-hidden '>
        <Container className="px-5">
            <header className="sectionHeader halfBorder mb-5 headerLeftMargin">Satyatma Teerta Swamiji visit</header>

            <div className="gallery d-flex flex-wrap"> 
                <figure data-aos="fade-up-right">
                    <img fluid  src="/photo_gallery/swamiji_visit/swamiji1.jpg" alt="" />
                </figure>   
                <figure data-aos="fade-up-left">
                    <img fluid  src="/photo_gallery/swamiji_visit/swamiji2.jpg" alt="" />
                </figure> 
                <figure data-aos="fade-up-right">
                    <img fluid  src="/photo_gallery/swamiji_visit/swamiji3.jpg" alt="" />
                </figure>   
                <figure data-aos="fade-up-left">
                    <img fluid  src="/photo_gallery/swamiji_visit/swamiji4.jpg" alt="" />
                </figure>
                <figure data-aos="fade-up-right">
                    <img fluid  src="/photo_gallery/swamiji_visit/swamiji5.jpg" alt="" />
                </figure>   
                <figure data-aos="fade-up-left">
                    <img fluid  src="/photo_gallery/swamiji_visit/swamiji6.jpg" alt="" />
                </figure> 
                <figure data-aos="fade-up-right">
                    <img fluid  src="/photo_gallery/swamiji_visit/swamiji7.jpg" alt="" />
                </figure>   
                <figure data-aos="fade-up-left">
                    <img fluid  src="/photo_gallery/swamiji_visit/swamiji8.jpg" alt="" />
                </figure>
                 
               
            </div>


        </Container>
    </div>
  )
}

export default SwamijiVisit