import React from "react"
import Layout from "../components/Layout"
import { Container, Tab, Nav } from "react-bootstrap"
import '../styles/tab_photos.css'
import GovardhanUtsav from "../components/photoGallery/GovardhanUtsav"
import GaneshFest from "../components/photoGallery/GaneshFest"
import IshwarappaVisit from "../components/photoGallery/IshwarappaVisit"
import SwamijiVisit from "../components/photoGallery/SwamijiVisit"
import ShramaDana from "../components/photoGallery/ShramaDana"
import Head from '../components/Head';




function PhotoGallery() {
    return (
        <div className='overflow-hidden '>
            <Head title="Photo Gallery"/>
            <Layout>
                <Container fluid className="px-5">
                    
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first" className="tabContainer">
                        <Nav variant="pills" className="flex-row campusTabs" >
                            <Nav.Item>
                                <Nav.Link className="tab" eventKey="first" >Govardhan Utsav</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link className="tab" eventKey="second">Ganesh Fest</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link className="tab" eventKey="third">Ishwarappa visit</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link className="tab" eventKey="fourth">Swamiji visit</Nav.Link>
                            </Nav.Item> 
                            <Nav.Item>
                                <Nav.Link className="tab" eventKey="fifth">Shrama Dana</Nav.Link>
                            </Nav.Item> 
                        </Nav>
                        <Tab.Content>
                            <Tab.Pane eventKey="first" className=''>
                                <GovardhanUtsav/>
                            </Tab.Pane>
                            <Tab.Pane eventKey="second" className=''>
                                <GaneshFest />
                            </Tab.Pane>
                            <Tab.Pane eventKey="third" className=''>
                                <IshwarappaVisit />
                            </Tab.Pane>
                            <Tab.Pane eventKey="fourth" className=''>
                                <SwamijiVisit />
                            </Tab.Pane>
                            <Tab.Pane eventKey="fifth" className=''>
                                <ShramaDana />
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>




                </Container>


            </Layout>
        </div>

    )
}

export default PhotoGallery;
